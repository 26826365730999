<template>
  <h3 class="mt-4">தமிழ் மின் நாணயம்</h3>
  <h4>TAMIL Crypto Project</h4>


  <div class="row">
    <!--Col 1-->
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
    <img src="@/assets/images/tamilcoin.png" alt="TamilCryptoCoin" style="width: 200px" />
      <p>
      செம்மொழிகளுள் ஒன்றாகிய தமிழ்மொழி, எக்காலத்திற்கும் உகந்த மொழியாக வளர்ந்துள்ளது. இதே தனித் தன்மையில், எதிர்காலத்திலும் தொடர்ந்து வளர்ந்து, சிறப்பும் செழிப்பும் நிறைந்த மொழியாகத் திகழ வேண்டும் என்பதையும் கருத்தில் கொண்டு தமிழ் மொழியின் மேம்பாட்டிற்கும் வளர்ச்சிக்கும் உறுதுணையாக விளங்கி, சிறப்பாகச் செயல்படும் தமிழ் அமைப்புகளையும், தனிநபர்களையும், தமிழ் ஆர்வலர்களையும் ஒன்று திரட்டி,மொழி வளர்ச்சிக்கு வலுவூட்டும் நடவடிக்கைகளைத் தமிழ்ச் சமூகத்துடன் ஒன்றிணைந்து தமிழ் மொழியை கட்டச்சங்கிலி (Blockchain) தொழினுட்பத்திலும் இடம் பெற்று மிளிரச் செய்வதே TamilToken.org செயல்திட்டமாகும்.
      <br><br>
      தந்தி (Telegram Channel) => <a href='https://t.me/tamiltoken'>https://t.me/tamiltoken</a>
      </p>



    </div>
    <!--Col 1-->

    <!--Col 2-->
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2">
        <div class="p-5">

          <div class="text-center">
            <h5  class="mb-3">Manifesto for TamilCrypto Project</h5>
          </div>

          <p>TamilCrypto is a revolutionary initiative harnessing the power of decentralized Blockchain technology to upload and validate digital assets in the Tamil language. Tamil, one of the oldest living classical languages, is not only the origin of many modern languages but also possesses unique characteristics that classify it as a classical language. With the aim of preserving and promoting Tamil heritage and culture, TamilToken empowers worldwide Tamil communities and language enthusiasts to participate in a global movement to safeguard the integrity of the Tamil language.</p>

          <h5>Mission:</h5>
          <p>Our mission is twofold: to protect and preserve the Tamil language and to empower global Tamil communities through decentralized technology. By leveraging blockchain and decentralized networks, TamilToken aims to create a secure and immutable platform where digital assets in Tamil can be uploaded, validated, and rewarded, ensuring their authenticity and longevity.</p>

          <h5>Vision:</h5>
          <p>We envision a future where the Tamil language thrives in the digital age, where its rich heritage and cultural significance are celebrated and preserved for generations to come. Through the TamilToken project, we strive to foster a global community of Tamil enthusiasts and language advocates who are committed to safeguarding Tamil's legacy and promoting its continued relevance in the modern world.</p>

          <h5>Key Principles:</h5>
          <ol>
              <li><strong>Decentralization:</strong> TamilToken operates on a decentralized peer-to-peer network, ensuring transparency, censorship resistance, and inclusivity.</li>
              <li><strong>Preservation:</strong> We are dedicated to preserving the integrity and authenticity of the Tamil language by creating a secure platform for the upload and validation of digital assets.</li>
              <li><strong>Inclusivity:</strong> TamilToken welcomes participation from Tamils and Tamil lovers worldwide, fostering a sense of community and collaboration in the protection and promotion of the Tamil language.</li>
              <li><strong>Rewards:</strong> Contributors to the TamilToken network are rewarded with TamilCrypto Tokens for their valuable contributions, incentivizing active participation and engagement.</li>
              <li><strong>Accessibility:</strong> TamilToken is designed to be accessible to all, providing a user-friendly interface and support for a wide range of devices and platforms.</li>
          </ol>

          <h5>How it Works:</h5>
          <ol>
              <li><strong>Upload:</strong> Users can upload digital assets such as documents, articles, audio recordings, videos, and more, in the Tamil language to the TamilToken platform.</li>
              <li><strong>Validation:</strong> Uploaded assets undergo a validation process to ensure their accuracy and authenticity, leveraging the collective wisdom and expertise of the TamilToken community.</li>
              <li><strong>Rewards:</strong> Contributors who actively participate in the validation process and contribute to the network's growth are rewarded with TamilCrypto Tokens, which can be redeemed for various incentives and rewards.</li>
              <li><strong>Community Engagement:</strong> TamilToken fosters community engagement through forums, discussions, and collaborative projects, encouraging knowledge sharing and collaboration among members.</li>
          </ol>

          <h5>Join the Movement:</h5>
          <p>TamilToken invites all Tamils, Tamil enthusiasts, and language advocates to join us in our mission to preserve and promote the Tamil language. Together, we can harness the power of decentralized technology to safeguard Tamil's legacy for future generations and ensure its continued relevance in the digital age.</p>

          <p><strong>Together, Let's Preserve Tamil Heritage with TamilToken!</strong></p>

          
        </div>

    </div>
    <!--Col 2-->
  </div>
</template>

<script>
export default {
  name: "airdrop",
};
</script>

<style scoped>
.box {
  padding: 10px;
}
.txt-body {
  text-align: left;
  font-weight: bold;
}

.box-heading {
  border: 1px solid rgba(100, 87, 87, 0.678);
  text-align: left;
  border-radius: 25px;
}
.box-heading > span {
  position: absolute;
  background: white;
  height: 20px;
  margin-top: -12px;
  margin-left: 10px;
  padding: 0 10px;
  font-weight: bold;
}
</style>
